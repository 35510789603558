
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-static/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.tsx'), universalOptions)
const t_1 = universal(import('../src/pages/change_denied.tsx'), universalOptions)
const t_2 = universal(import('../src/pages/index.tsx'), universalOptions)
const t_3 = universal(import('../src/pages/major.tsx'), universalOptions)
const t_4 = universal(import('../src/pages/step1.tsx'), universalOptions)
const t_5 = universal(import('../src/pages/step2.tsx'), universalOptions)
const t_6 = universal(import('../src/pages/step3.tsx'), universalOptions)
const t_7 = universal(import('../src/pages/step4.tsx'), universalOptions)
const t_8 = universal(import('../src/pages/thankyou.tsx'), universalOptions)
const t_9 = universal(import('../src/pages/verify.tsx'), universalOptions)
const t_10 = universal(import('../src/pages/major'), universalOptions)
const t_11 = universal(import('../src/pages/step1'), universalOptions)
const t_12 = universal(import('../src/pages/step2'), universalOptions)
const t_13 = universal(import('../src/pages/step3'), universalOptions)
const t_14 = universal(import('../src/pages/verify'), universalOptions)
const t_15 = universal(import('../src/pages/step4'), universalOptions)


// Template Map
export default {
  '../src/pages/404.tsx': t_0,
'../src/pages/change_denied.tsx': t_1,
'../src/pages/index.tsx': t_2,
'../src/pages/major.tsx': t_3,
'../src/pages/step1.tsx': t_4,
'../src/pages/step2.tsx': t_5,
'../src/pages/step3.tsx': t_6,
'../src/pages/step4.tsx': t_7,
'../src/pages/thankyou.tsx': t_8,
'../src/pages/verify.tsx': t_9,
'../src/pages/major': t_10,
'../src/pages/step1': t_11,
'../src/pages/step2': t_12,
'../src/pages/step3': t_13,
'../src/pages/verify': t_14,
'../src/pages/step4': t_15
}

export const notFoundTemplate = "../src/pages/404.tsx"
