// Imports


// Plugins
const plugins = [{
  location: "/opt/build/repo/node_modules/react-static-plugin-emotion",
  plugins: [],
  hooks: {}
},
{
  location: "/opt/build/repo/node_modules/react-static-plugin-typescript",
  plugins: [],
  hooks: {}
},
{
  location: "/opt/build/repo/node_modules/react-static-plugin-sass",
  plugins: [],
  hooks: {}
},
{
  location: "/opt/build/repo",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins